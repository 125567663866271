<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        addNew: true,
        action: {
          child: 'collection',
          showEditAll: true,
          showChildList: true,
          actions: [
            {
              tooltip: 'Add document',
              icon: 'mdi-plus',
              click: this.addPage,
            },
          ],
        },
        headers: [{ text: 'Name', value: 'name' }],
      },
    };
  },
  methods: {
    addPage({ _id }) {
      this.$add('collection', { id: _id });
    },
  },
};
</script>
